import request from '@/service/request'

/* 活动 page接口
* keyword
* pageNum
* pageSize
* */
export const pageActivityApi = (params) => {
  return request.post('/app/activity/pageAdmin', params)
}

/* 活动 获取接口
* id
* */
export const getActivityByIdApi = (id) => {
  return request.post(`/app/activity/getById/${id}`)
}

/* 活动 删除接口
* id
* */
export const delActivityApi = (id) => {
  return request.post(`/app/activity/del/${id}`)
}

/* 活动 保存接口 */
export const saveActivityApi = (params) => {
  return request.post('/app/activity/save', params)
}

/* 活动 获取报名记录
* activityId
*  keyword
*  pageNum
* pageSize
* userId
* */
export const pageActivityJoinRecordApi = (params) => {
  return request.post('/app/activity/pageJoinRecord', params)
}
