<template>
  <div class="common-form">
    <!-- header -->
    <el-card>
      <div class="header-button">
        <el-button @click="closePublish">取消</el-button>
        <el-button type="primary" @click="addNewsHandle">立即发布</el-button>
      </div>
      <el-form ref="form" :model="formData" :rules="comptRules" label-width="120px">
        <el-form-item label="活动标题" prop="title">
          <el-input type="textarea" rows="2" v-model.trim="formData.title" />
        </el-form-item>
        <el-form-item label="公众号地址" prop="mpUrl">
          <el-input type="textarea" rows="2" v-model.trim="formData.mpUrl" />
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item prop="previewImg">
              <template slot="label">
                <tool-tips-group tips-content="建议封面尺寸：900px × 383px">
                  封面图片<br/>(900×383)
                </tool-tips-group>
              </template>
              <single-img-uploader :url.sync="formData.previewImg" width="220px" height="94px" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否首页显示" prop="topFlag" label-width="200px">
              <el-switch
                v-model="formData.topFlag"
                active-color="var(--color-success)"
                inactive-color="#d0d6e8"
                :active-value="1"
                active-text="是"
                :inactive-value="0"
                inactive-text="否" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="报名开始时间" prop="startTime">
              <el-date-picker :picker-options="comptStartTime" v-model="formData.startTime" type="datetime"
                              placeholder="报名开始时间"
                              value-format="yyyy-MM-dd HH:mm:ss" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="报名结束时间" prop="endTime">
              <el-date-picker :picker-options="comptEndTime" v-model="formData.endTime" type="datetime"
                              placeholder="报名结束时间"
                              value-format="yyyy-MM-dd HH:mm:ss" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="院系">
          <el-select v-model="formData.collegeId" :disabled="!!$store.state.login.userInfo.collegeId" clearable filterable style="width:300px;">
            <el-option
              v-for="{collegeName,id} in collegeList"
              :key="id"
              :label="collegeName"
              :value="id" />
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="remarks">
          <el-input type="textarea" rows="3" v-model.trim="formData.remarks" />
        </el-form-item>
      </el-form>
      <vue-tinymce @change="changeContent" :value="formData.content"
                   :imagesUploadHandler="imagesUploadHandler" />
    </el-card>
  </div>
</template>

<script>
import { newsUploadImg } from '@/service/newsHttp'
import { getActivityByIdApi, saveActivityApi } from '@/api/app-manage/activity-api'

const host = process.env.VUE_APP_FILE_URL
export default {
  name: 'activityForm',
  data () {
    return {
      collegeList: [],
      formData: {
        id: null,
        title: '', // 标题
        mpUrl: '', // 公众号地址
        collegeId: this.$store.state.login.userInfo.collegeId || '',
        previewImg: '', // 预览图片
        startTime: '',
        endTime: '',
        content: '', // 文本内容
        topFlag: 0, // 是否首页显示 1是 0否
        remarks: ''
      },
      disabledStartTime: {
        disabledData: (time) => {
          return time.getTime() + 24
        }
      }
    }
  },
  computed: {
    comptStartTime () { // 开始日期不能早于结束日期
      let _endTime = 0
      if (this.formData.endTime) {
        _endTime = this.formData.endTime.replace(/-/g, '/')
        _endTime = new Date(_endTime).getTime()
      }
      return {
        disabledDate: time => {
          if (_endTime) {
            return time.getTime() > _endTime
          }
        }
      }
    },
    comptEndTime () { // 结束日期不能早于开始日期
      let _startTime = 0
      if (this.formData.startTime) {
        _startTime = this.formData.startTime.replace(/-/g, '/')
        _startTime = new Date(_startTime).getTime()
      }
      return {
        disabledDate: time => {
          if (_startTime) {
            return time.getTime() < _startTime
          }
        }
      }
    },
    comptRules () {
      const _rules = {
        title: [{
          required: true,
          message: '请填写活动标题',
          trigger: 'blur'
        },
          {
            message: '不能超过110个字',
            trigger: 'blur',
            max: 110
          }
        ],
        mpUrl: [{
          message: '不能超过1000个字',
          trigger: 'blur',
          max: 1000
        }, {
          validator: (rule, value, callback) => {
            if (value && !/^https:\/\/mp.weixin.qq.com\/[^\s]*$/.test(value)) {
              return callback(new Error('公众号地址格式不正确!'))
            } else {
              return callback()
            }
          },
          trigger: 'blur'
        }],
        remarks: {
          message: '不能超过200个字',
          trigger: 'blur',
          max: 200
        },
        content: {},
        startTime: {
          required: true,
          message: '请填写报名开始时间',
          trigger: 'blur'
        },
        endTime: [{
          required: true,
          message: '请填写报名结束时间',
          trigger: 'blur'
        }]
      }
      if (this.formData.mpUrl) {
        _rules.content = {}
      } else {
        _rules.content = {
          required: true,
          message: '请填写活动内容',
          trigger: 'blur'
        }
      }
      return _rules
    }
  },
  created () {
    this.getCollegeList()
    if (this.$route.query.id) {
      this.getDataById(this.$route.query.id)
    }
  },
  methods: {
    getCollegeList () {
      this.$http.getCollegeListQuery({}).then((res) => {
        this.collegeList = res.data
      })
    },
    // 根据id获取新闻信息
    getDataById (id) {
      getActivityByIdApi(id).then((res) => {
        Object.keys(this.formData).forEach((item) => {
          this.formData[item] = res.data[item]
        })
      })
    },
    // 编辑内容
    changeContent (richText) {
      this.formData.content = richText
    },
    // 发布新闻
    addNewsHandle () {
      if (!this.formData.content && !this.formData.mpUrl) {
        this.$message.error('请输入活动内容或输入公众号链接!')
        return false
      }
      if (!this.formData.previewImg && this.formData.topFlag) {
        this.$message.error('缺少活动封面，无法设置首页显示!')
        this.formData.topFlag = 0
        return false
      }
      const _startTime = new Date(this.formData.startTime.replace(/-/g, '/')).getTime()
      const _endTime = new Date(this.formData.endTime.replace(/-/g, '/')).getTime()
      if (_startTime > _endTime) {
        this.$message.error('报名结束时间不能早于报名开始时间!')
        // this.formData.topFlag = 0
        return false
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$confirm('是否确定发布活动?').then(() => {
            saveActivityApi(this.formData).then((res) => {
              this.$message.success('活动添加成功!')
              this.$router.push('/appManage/activity')
            }).catch((e) => {
              if (!e) {
                this.$message.error('操作失败，请重试！')
              }
            })
          }).catch()
        } else {
          this.$message('未通过校验,可能是漏填必填项!')
          return false
        }
      })
    },
    // 取消发布
    closePublish () {
      this.$router.push('/appManage/activity')
    },
    imagesUploadHandler (blobInfo, success, failure) {
      newsUploadImg(blobInfo.blob()).then(res => {
        success(host + res.data.data)
      })
    }
  }
}
</script>
